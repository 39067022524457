import getEnv from './getEnv'

const urls = {
  local: `http://webadmin.local:5140`,
  dev: 'https://webadmin.bejusto.com',
  prod: 'https://webadmin.getjusto.com'
}

const env = getEnv()

export default urls[env]
