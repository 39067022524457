import {MdLogin} from 'react-icons/md'
import {useLoginViaAuthWindow} from '@helpers/auth/loginViaAuth'

import styles from './styles.module.css'

export default function LoginButton() {
  const loginViaAuth = useLoginViaAuthWindow('navbar')
  return (
    <a onClick={() => loginViaAuth()} className={styles.menuText}>
      Iniciar sesión <MdLogin />
    </a>
  )
}
