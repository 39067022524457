export default function WebsiteCoinsImage({width = 20, height = 20, svgClass = null}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 22"
      fill="currentColor"
      className={svgClass}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3098 21.3803C25.2138 21.3803 30 16.5942 30 10.6902C30 4.78615 25.2138 0 19.3098 0C13.4058 0 8.61963 4.78615 8.61963 10.6902C8.61963 16.5942 13.4058 21.3803 19.3098 21.3803ZM16.781 7.57122L19.3123 2.82918L21.8436 7.57122L27.033 8.56934L23.408 12.4982L24.0839 17.8571L19.3123 15.5433L14.5407 17.8571L15.2166 12.4982L11.5916 8.56934L16.781 7.57122Z"
        fill="currentColor"
      />
      <path
        d="M19.3125 2.8291L16.7812 7.57115L11.5918 8.56926L15.2168 12.4981L14.5408 17.857L19.3125 15.5432L24.0841 17.857L23.4081 12.4981L27.0331 8.56926L21.8437 7.57115L19.3125 2.8291Z"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2397 0.0429048C11.9062 0.0145056 11.5684 0 11.227 0C5.0265 0 0 4.78615 0 10.6902C0 16.5942 5.0265 21.3803 11.227 21.3803C11.5218 21.3803 11.8139 21.3695 12.103 21.3483C8.38236 19.1502 5.90373 15.2191 5.90373 10.7354C5.90373 6.19702 8.4432 2.2248 12.2397 0.0429048Z"
        fill="currentColor"
      />
    </svg>
  )
}
