import {GetMeInNavbarQuery, GetMeInNavbarQueryVariables} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useUserInfo() {
  const websiteId = useWebsiteId()
  const {data} = useApolloQuery<GetMeInNavbarQuery, GetMeInNavbarQueryVariables>({
    query: gql`
      query getMeInNavbar($websiteId: ID) {
        me {
          _id
          name
          email
          roles
          roleInWebsite(websiteId: $websiteId) {
            _id
            role
          }
          hiddenEmail
          pointsInWebsite(websiteId: $websiteId)
          websiteCoinsBalance(websiteId: $websiteId)
        }
        loyaltyProgramConfig(websiteId: $websiteId) {
          _id
          name
          active
          maxRedeemAmount
        }
      }
    `,
    variables: {websiteId}
  })

  return data
}
