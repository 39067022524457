import React, {useEffect, useState} from 'react'
import {MdKeyboardArrowLeft as LeftArrow} from 'react-icons/md'
import Loading from '@components/Loading'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import isEqual from 'lodash/isEqual'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'

import mainStyles from '../GoBack/styles.module.css'
import localStyles from './styles.module.css'
const styles = {...mainStyles, ...localStyles}

const query = gql`
  query getCategoriesForMenu($websiteId: ID) {
    categories(websiteId: $websiteId) {
      items {
        _id
        name
        subCategories {
          items {
            _id
            name
          }
        }
      }
    }
  }
`

export default function MobileCategoriesMenu({setOpen}) {
  const websiteId = useWebsiteId()
  const router = useRouter()
  const [selected, setSelected] = useState<any>()
  const [arrowClicked, setArrowClicked] = useState(false)
  const {t} = useTranslation()
  const i18nGeneric = 'generic:fields'
  const i18nWebsite = 'website:order'
  const {categories, loading} = useApolloQuery({
    query,
    variables: {websiteId},
    fetchPolicy: 'cache-first'
  })

  const goBackToCategory = () => {
    setSelected(null)
    setArrowClicked(true)
  }

  useEffect(() => {
    if (selected === null && arrowClicked) return
    if (router.asPath?.includes('/pedir/category/')) {
      const categoryIdSelected = router.asPath?.split('/')?.pop()
      const newSelected = categories.items.find(category => category._id === categoryIdSelected)
      if (!isEqual(newSelected, selected) || arrowClicked) {
        setSelected(newSelected)
        setArrowClicked(false)
      }
    }
  }, [router, categories.items, arrowClicked, selected])

  if (loading || !categories) {
    return (
      <>
        <div className={styles.closeMenu} onClick={() => setOpen(false)}>
          {t(`${i18nGeneric}.close`)}
        </div>
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.closeMenu} onClick={() => setOpen(false)}>
        {t(`${i18nGeneric}.close`)}
      </div>
      <div className={styles.container}>
        <div className={styles.categories}>
          <div className={styles.header}>
            {selected ? (
              <span className={styles.textWithIcon} onClick={goBackToCategory}>
                <LeftArrow size="22" />
              </span>
            ) : null}
            <h2>
              {selected ? (
                <span
                  className={styles.primaryColor}
                  onClick={() => {
                    setOpen(false)
                    router.push(`/pedir/category/${selected._id}`)
                  }}>
                  {selected.name}
                </span>
              ) : (
                t(`${i18nWebsite}.labelCategories`)
              )}
            </h2>
          </div>
          <div className={styles.categoriesList}>
            {(selected ? selected.subCategories : categories).items.map((category, idx) => (
              <div
                key={idx}
                className={styles.categoryItem}
                onClick={() => {
                  setOpen(false)
                  router.push(`/pedir/category/${category._id}`)
                  setSelected(category)
                }}>
                {category.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
