import useIsDarkMode from '@hooks/useIsDarkMode'
import useShouldUseLoyaltyV2 from '@page-components/CheckoutV3/sections/useShouldUseLoyaltyV2'
import WebsiteCoinsImage from '@page-components/CheckoutV3/sections/WebsiteCoins/WebsiteCoinsCount/WebsiteCoinsImage'
import Link from 'next/link'

type Props = {
  loyaltyProgramConfig: any
  me: any
}

export default function WebsiteCoins(props: Props) {
  const showJustoLoyaltyV2 = useShouldUseLoyaltyV2()
  const isDarkMode = useIsDarkMode()

  if (!showJustoLoyaltyV2) return null

  const {loyaltyProgramConfig, me} = props

  if (!loyaltyProgramConfig?.active || (!me?.websiteCoinsBalance && me?.websiteCoinsBalance !== 0))
    return null

  return (
    <Link href="/justoloyalty" passHref legacyBehavior>
      <div className="flex px-2 py-1 space-x-1 items-center">
        <WebsiteCoinsImage svgClass={isDarkMode ? 'secondaryColor' : 'primaryColor'} />
        <span>{me.websiteCoinsBalance}</span>
      </div>
    </Link>
  );
}
