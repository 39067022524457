import React from 'react'
import ClickOutside from 'react-click-outside'

interface BaseProps {
  avoidElementName?: string
  avoidElementsClass?: string
  onClickOutside: (e: any) => void
  children: any
}

export type Props = BaseProps & React.HTMLAttributes<HTMLDivElement>

export default function ClickOutsideCustom(props: Props) {
  const {
    avoidElementName,
    avoidElementsClass,
    children,
    onClickOutside: onClickOutsideProps,
    ...otherProps
  } = props

  const onClickOutside = e => {
    if (avoidElementName) {
      const element = document.getElementById(avoidElementName)
      if (element && element.contains(e.target)) {
        return
      }
    }

    if (avoidElementsClass) {
      const elements = document.getElementsByClassName(avoidElementsClass)
      if (elements?.length && [...elements].some(element => element.contains(e.target))) {
        return
      }
    }

    return onClickOutsideProps?.(e)
  }

  return (
    // react-click-outside passes the other props to the div containing the children
    <ClickOutside {...otherProps} onClickOutside={onClickOutside}>
      {children}
    </ClickOutside>
  )
}
