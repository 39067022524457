import React, {useEffect, useState} from 'react'
import {
  MdExitToApp as LogoutIcon,
  MdKeyboardArrowDown as OpenMenuIcon,
  MdSettings as SettingsIcon,
  MdShoppingCart as MyCartIcon,
  MdShopTwo as MyOrdersIcon,
  MdStore as SalesPointIcon
} from 'react-icons/md'
import ClickOutsideCustom from '@components/ClickOutside'
import ClientSuspense from '@components/ClientSuspense'
import logout from '@helpers/auth/logout'
import getContrastColor from '@helpers/misc/getContrastColor'
import {getSavedValue} from '@hooks/useSessionStorageState'
import useUserId from '@hooks/useUserId'
import useInitialData from '@page-components/Layout/useInitialData'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

import Points from './Points/index'
import AdminLink from './AdminLink'
import canViewAdmin from './canViewAdmin'
import canViewPos from './canViewPos'
import LoginButton from './LoginButton'
import useUserInfo from './useUserInfo.main'
import WebsiteCoins from './WebsiteCoins'

import styles from './styles.module.css'

type ComponentProps = {
  open?: boolean
  closeMenu?: any
  toggleOpen?: any
  pointsConfiguration: any
  buttonColor: string
  onlyOptions?: boolean
}

const translatePrefix = 'layout.layouts.default.navbar.menu'

const MenuInner = ({
  open: initialOpen,
  toggleOpen,
  pointsConfiguration,
  buttonColor,
  onlyOptions
}: ComponentProps) => {
  const {t} = useTranslation('website')
  const [open, setOpen] = useState(initialOpen)
  const userId = useUserId()
  const {me, loyaltyProgramConfig} = useUserInfo()
  const {website} = useInitialData()

  const isLoggedInWithExternalProviders = website => {
    const isOpenByWebview = getSavedValue('openByWebview')
    return (
      isOpenByWebview && website?.loginWithExternalProvidersAvailable?.includes(isOpenByWebview)
    )
  }

  useEffect(() => {
    setOpen(initialOpen)
  }, [initialOpen])

  const toggleMenu = () => {
    setOpen(!open)
    if (toggleOpen) {
      toggleOpen()
    }
  }
  const onLogout = async () => {
    await logout()
  }

  // ... [implement other methods like renderAdmin, renderSalesPoint, etc., as functions within this functional component]
  const renderAdmin = () => {
    const canView = canViewAdmin(me)
    if (!canView) return null
    return <AdminLink />
  }

  const renderSalesPoint = () => {
    const canView = canViewPos(me)
    if (!canView) return null
    return (
      <a href="https://pos.getjusto.com" target="blank" className={styles.menuLink}>
        <SalesPointIcon size={20} />
        <span>{t(`${translatePrefix}.labelPointOfSale`)}</span>
      </a>
    )
  }

  const renderMenu = () => {
    if (!me) return null
    if (!open) return null
    const hideLogoutAndSettings = isLoggedInWithExternalProviders(website)
    const settingsURL = hideLogoutAndSettings ? '#' : '/settings'

    return (
      <div className={styles.menu} key="menu">
        <Link href={settingsURL} passHref className={styles.account}>
          <div className={styles.name}>{me.name === 'Sin nombre' ? 'Mi cuenta' : me.name}</div>
          <div className={styles.email}>{me.hiddenEmail}</div>
        </Link>
        <Points user={me} config={pointsConfiguration} />
        <Link href={'/checkout'} className={styles.menuLink}>
          <MyCartIcon size={20} />
          <span>{t('fields.myCart', {ns: 'generic'})}</span>
        </Link>
        <Link href="/pedidos" className={styles.menuLink}>
          <MyOrdersIcon size={20} />
          <span>{t('fields.myOrders', {ns: 'generic'})}</span>
        </Link>
        {hideLogoutAndSettings ? null : (
          <Link
            href={settingsURL}
            className={styles.menuLink}
            target="_blank"
            rel="noopener noreferrer">
            <SettingsIcon size={20} />
            <span>{t(`${translatePrefix}.labelMyAccount`)}</span>
          </Link>
        )}
        {renderAdmin()}
        {renderSalesPoint()}
        {hideLogoutAndSettings ? null : (
          <div className={styles.logoutIcons}>
            <a onClick={onLogout} className={styles.menuLink}>
              <LogoutIcon size={20} />
              <div>{t(`${translatePrefix}.labelLogOut`)}</div>
            </a>
          </div>
        )}
      </div>
    )
  }

  const renderIcon = () => {
    if (me) {
      return (
        <div className="flex divide-x">
          <div className={styles.menuText} onClick={toggleMenu}>
            {me.name === 'Sin nombre' ? t(`${translatePrefix}.labelMyAccount`) : me.name}{' '}
            <OpenMenuIcon />
          </div>
          <WebsiteCoins loyaltyProgramConfig={loyaltyProgramConfig} me={me} />
        </div>
      )
    } else if (!userId) {
      return <LoginButton />
    } else {
      return <div className={styles.menuText}>{t(`${translatePrefix}.labelLoading`)}</div>
    }
  }

  if (onlyOptions) return renderMenu()

  const hideLabelMyAccount = isLoggedInWithExternalProviders(website) && !userId
  if (hideLabelMyAccount) return null

  return (
    <ClickOutsideCustom onClickOutside={open ? toggleMenu : () => null}>
      <div
        className={styles.container}
        style={{
          background: `${buttonColor}`,
          color: getContrastColor(buttonColor || '#111')
        }}>
        {renderIcon()}

        {renderMenu()}
      </div>
    </ClickOutsideCustom>
  )
}

export default function Menu(props: ComponentProps) {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  if (!loaded) return null

  return (
    <ClientSuspense fallback={null}>
      <MenuInner {...props} />
    </ClientSuspense>
  )
}
